<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center">
          <h4 class="font-weight-light">{{ $t('heading.hosting.emailAddress.title') }}</h4>
        </v-col>

        <v-col
          v-show="items.length"
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <data-iterator-filter-controls
            :keys="keys"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            :fullWidthSearch="false"
            :fullWidthLg="false"
            :fullWidthMd="true"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="loadAddModal()"
            :loading="addButtonLoading"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus</v-icon
            >
            {{ $vuetify.breakpoint.mobile ? "" : "Add Address" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-table
              :checkedItems="checkedItems"
              :headers="headers"
              :items="items"
              :itemsLoading="loading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              :itemClass="itemClass"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.emailAddress.title')"
                  :desc="$t('message.emptyTable.emailAddress.description')"
                >
                  <template v-slot:image>
                    <hosting-email-addresses-illustration />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      class="p-2 add-new-button"
                      @click="loadAddModal()"
                      :loading="addButtonLoading"
                      rounded
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus</v-icon
                      >
                      {{
                        $vuetify.breakpoint.mobile ? "" : $t('button.emailAddress.add')
                      }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>
              <template v-slot:actions="item">
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showEditModal(item)"
                    >
                      <v-icon>$edit2</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.emailAddress.update') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="loadDetailsModal(item)"
                      :loading="item.detailsButtonLoading"
                    >
                      <v-icon>$eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.emailAddress.show') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showDeleteModal(item)"
                    >
                      <v-icon>$thrash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.emailAddress.delete') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="webmailSso(item)"
                      :loading="item.webmailButtonLoading"
                    >
                      <v-icon>$mail</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.emailAddress.logIn') }}</span>
                </v-tooltip>
              </template>

              <template v-slot:mobile="{ item, headers }">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.email') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.email }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.usageQuota') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.usage_quota }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:updated_at="item">
                <span>{{ item.updated_at_text }}</span>
              </template>
            </hosting-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <account-configuration-details
        style="width: 560px"
        @modal-close="modalClose"
        :details="modalOptions.message"
        v-if="modalOptions.type == 'email-details'"
      />
      <basic-modal
        v-else
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingTable from "../../components/dataIterator/tables/HostingTable.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingEmailAddressesIllustration from "../../components/illustrations/hosting/hosting-email-addresses-illustration.vue";
import AccountConfigurationDetails from "../../components/modal/AccountConfigurationDetails.vue";

export default {
  components: {
    BasicModal,
    DataIteratorFilterControls,
    HostingTable,
    TableFullScreenMessage,
    HostingEmailAddressesIllustration,
    AccountConfigurationDetails,
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin],
  data() {
    return {
      isServerProvided: false,
      loading: true,
      addButtonLoading: false,
      items: [],
      itemsPerPage: 8,
      page: 1,
      search: "",
      sortDesc: true,
      sortBy: "updated_at",
      headers: [
        {
          text: this.$t('table.header.email'),
          value: "email",
          sortable: true,
        },
        {
          text: this.$t('table.header.usageQuota'),
          value: "usage_quota",
          sortable: true,
        },
        {
          text: this.$t('table.header.updated'),
          value: "updated_at",
          sortable: true,
        },
        {
          text: this.$t('table.header.actions'),
          value: "actions",
        },
      ],
      modalRender: 0,
      modalOptions: { open: false },
      highlightItem: {},
    };
  },
  computed: {
    itemsTotal: function () {
      return this.items.length;
    },
  },
  props: {
    serverAccount: Object,
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    serverAccount: function () {
      this.reloadData();
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  methods: {
    loadAddModal() {
      this.addButtonLoading = true;
      Api.cached()
        .get(`/server-accounts/${this.serverAccount.id}/email/domains`)
        .then((response) => {
          this.showAddModal(response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.addButtonLoading = false;
        });
    },
    showAddModal(domains) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.add.title');
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.emailAddress.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addEmailAccount(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.email'),
          type: "text-select",
          suffix: "@",
          tooltip: "tooltip.add_email_account.email",
          textName: "email",
          selectName: "domain",
          options: domains.map((item) => {
            return {
              label: item.domain,
              value: item.domain,
            };
          }),
          rules: [(v) => (v && /.+@.+/.test(v)) ||
            this.$t('form.validation.required', {field: this.$t('form.label.email')})
          ],
        },
        {
          label: this.$t('form.label.password'),
          name: "password",
          tooltip: "tooltip.add_email_account.password",
          type: "generatePassword",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.password')}),
            (v) =>
              (v && v.length >= 6) ||
                this.$t('form.validation.minLength', {
                  field: this.$t('form.label.password'),
                  length: 6
                }),
          ],
        },
        {
          label: this.$t('form.label.unlimitedQuota'),
          name: "unlimited_quota",
          tooltip: "tooltip.add_email_account.unlimited_quota",
          type: "checkbox",
          onChange: (values) => {
            for (const field of this.modalOptions.formFields) {
              if (field.name == "quota") {
                field.hidden = values.unlimited_quota;
              }
            }
          },
        },
        {
          label: this.$t('form.label.quota'),
          name: "quota",
          tooltip: "tooltip.add_email_account.quota",
          type: "text",
          hidden: true,
        },
      ];
      this.modalOptions.item = {
        domain: domains[0].domain,
        unlimited_quota: true,
      };
      this.modalOptions.open = true;
    },
    addEmailAccount(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      if (formData.unlimited_quota) {
        formData.quota = 0;
      }
      Api.post(
        `/server-accounts/${this.serverAccount.id}/email/accounts`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailAddress.add.success');
          this.highlightItem = {
            email: `${formData.email}@${formData.domain}`,
          };
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showEditModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.update.title', {email: `<span style="text-wrap: nowrap;">${item.email}</span>`});
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.emailAddress.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateEmailAccount(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.password'),
          name: "hiddenPassword",
          tooltip: "tooltip.edit_email_account.password",
          type: "hiddenPassword",
          onButtonClick: () => {
            for (const field of this.modalOptions.formFields) {
              if (field.name == "hiddenPassword") {
                field.hidden = true;
              }
              if (field.name == "password") {
                field.hidden = false;
              }
            }
          },
        },
        {
          label: this.$t('form.label.password'),
          name: "password",
          tooltip: "tooltip.edit_email_account.password",
          type: "generatePassword",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.password')}),
            (v) =>
              (v && v.length >= 6) ||
                this.$t('form.validation.minLength', {
                  field: this.$t('form.label.password'),
                  length: 6
                }),
          ],
          hidden: true,
        },
        {
          label: this.$t('form.label.unlimitedQuota'),
          name: "unlimited_quota",
          tooltip: "tooltip.edit_email_account.unlimited_quota",
          type: "checkbox",
          onChange: (values) => {
            for (const field of this.modalOptions.formFields) {
              if (field.name == "quota") {
                field.hidden = values.unlimited_quota;
              }
            }
          },
        },
        {
          label: this.$t('form.label.quota'),
          name: "quota",
          tooltip: "tooltip.edit_email_account.quota",
          type: "text",
          hidden: item.disk_quota == "unlimited",
        },
      ];
      if (item.disk_quota == "unlimited") {
        this.modalOptions.item = { unlimited_quota: true, quota: "" };
      } else {
        this.modalOptions.item = {
          unlimited_quota: false,
          quota: parseInt(item.disk_quota),
        };
      }

      this.modalOptions.open = true;
    },

    updateEmailAccount(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      if (formData.unlimited_quota) {
        formData.quota = 0;
      }
      Api.put(
        `/server-accounts/${this.serverAccount.id}/email/accounts/${item.email}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailAddress.update.success');
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.highlightItem = { email: item.email };
          this.reloadData();
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.delete.title');
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.emailAddress'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];
      this.modalOptions.buttons.unshift({
        label: this.$t('button.emailAddress.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteAccount(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteAccount(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/email/accounts/${item.email}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailAddress.delete.success');
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    loadDetailsModal(item) {
      item.detailsButtonLoading = true;
      Api.cached()
        .get(
          `/server-accounts/${this.serverAccount.id}/email/accounts/${item.email}`
        )
        .then((response) => {
          this.showDetailsModal(item, response.data.data);
          item.detailsButtonLoading = false;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
          item.detailsButtonLoading = false;
        })
        .finally(() => {
          item.detailsButtonLoading = false;
        });
    },
    showDetailsModal(item, details) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.show.title');
      this.modalOptions.type = "email-details";
      this.modalOptions.icon = "$eye";

      this.modalOptions.message = details;
      this.modalOptions.open = true;
    },
    webmailSso(item) {
      item.webmailButtonLoading = true;
      Api.cached()
        .get(
          `/server-accounts/${this.serverAccount.id}/email/accounts/${item.email}/webmail-sso-url`
        )
        .then((response) => {
          window.location.href = response.data.data.url;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          item.webmailButtonLoading = false;
        });
    },
    reloadData() {
      this.loading = true;
      Api.get(`/server-accounts/${this.serverAccount.id}/email/accounts`)
        .then((response) => {
          this.items = response.data.data.map((item) => {
            item.usage_quota = `${this.$t('form.value.sizeMB', {value: item.disk_usage})} / ${
              item.disk_quota == "unlimited"
                ? this.$t('form.value.unlimited')
                : this.$t('form.value.sizeMB', {value: item.disk_quota})
            }`;
            item.detailsButtonLoading = false;
            item.webmailButtonLoading = false;
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            return item;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    itemClass(item) {
      if (this.highlightItem.email && item.email == this.highlightItem.email) {
        return "highlight";
      }
    },
  },
  mounted() {
    if (this.serverAccount) {
      this.reloadData();
    }
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
</style>
